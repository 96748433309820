import React from 'react'
import { Redirect } from 'react-router'
import Box from '../components/Box/Box'
import MinimalLayout from '../components/Layout/MinimalLayout'
import { UserContext } from '../contexts'
import BoxInlineList from '../components/BoxInlineList/BoxInlineList'
import { Link } from 'react-router-dom'
import { fetchUserCompanies } from '../requests/users'

export default class Login extends React.Component {
  static contextType = UserContext

  state = {
    companies: []
  }

  componentDidMount () {
    this.refreshCompanies()
  }

  refreshCompanies = () => {
    const { token, bundle } = this.context
    fetchUserCompanies(token, bundle._id)
      .then(companies => {
        this.setState({ companies })
      })
  }

  createElement = () => {
    const { bundle } = this.context

    return ({ instance }) =>
      <Link to={`/users/${bundle._id}/detail?companyId=${instance._id}`}>
        {instance.name}
      </Link>
  }

  render () {
    const { companies } = this.state
    const { bundle } = this.context

    return (
      <MinimalLayout>
        {
          !bundle.isAdmin &&
          <Box title="Empresas" subtext="Seleccione la empresa a la que ingresará">
            <BoxInlineList data={companies} element={this.createElement()}/>
          </Box>
        }

        <div style={{ padding: 8, textAlign: 'center' }}>
          {
            bundle.isAdmin &&
            <Redirect to="/admin/companies"/>
          }
        </div>
      </MinimalLayout>
    )
  }
}
