export const ENDPOINT_COMPANY_CREATE = 'https://usuarios.dev.iperdinamico.cl/core/companies'
export const ENDPOINT_COMPANIES = 'https://usuarios.dev.iperdinamico.cl/core/companies'
export const ENDPOINT_USERS = 'https://usuarios.dev.iperdinamico.cl/core/users'
export const ENDPOINT_DOCUMENT_DEFINITIONS = 'https://usuarios.dev.iperdinamico.cl/documents/definitions'
export const ENDPOINT_DOCUMENT_SUBMISSIONS = 'https://usuarios.dev.iperdinamico.cl/documents/submissions'
export const ENDPOINT_SII = 'https://usuarios.dev.iperdinamico.cl/sii'
export const ENDPOINT_PERMISSIONS = 'https://usuarios.dev.iperdinamico.cl/permissions'
export const ENDPOINT_IPER = 'https://usuarios.dev.iperdinamico.cl/core/iper'
export const ENDPOINT_BLACK_LIST = 'https://usuarios.dev.iperdinamico.cl/core/tokens'

/*
export const ENDPOINT_COMPANY_CREATE = 'http://localhost:4000/companies'
export const ENDPOINT_COMPANIES = 'http://localhost:4000/companies'
export const ENDPOINT_USERS = 'http://localhost:4000/users'
export const ENDPOINT_DOCUMENT_DEFINITIONS = 'http://localhost:5000/definitions'
export const ENDPOINT_DOCUMENT_SUBMISSIONS = 'http://localhost:5000/submissions'
*/
