import React from 'react'
import PropTypes from 'prop-types'
import { Button, FormGroup, Input, Label, ListGroup, ListGroupItem } from 'reactstrap'
import { UserContext } from '../contexts'
import { fetchUserPermissions, updateUserPermissions } from '../requests/permissions'
import { fetchAreasByUser } from '../requests/iper'

export default class IPER extends React.Component {
  static contextType = UserContext

  static propTypes = {
    userId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
  }

  state = {
    canAdmin: false,
    canAccess: false,
    isLoading: true,
    areas: {},
    areasToEdit: {},
    userAreas: []
  }

  async componentDidMount () {
    const { userId, companyId } = this.props
    const { token } = this.context
    await fetchAreasByUser(token, companyId, userId)
      .then(userAreas => {
        if (!userAreas.error) {
          const areasToEdit = {}
          userAreas.forEach(
            val => areasToEdit[val._id] = { canCreate: false, canReview: false }
          )

          this.setState({
            userAreas,
            areasToEdit
          })
        }
      })
    fetchUserPermissions(token, userId, companyId, 'IPER')
      .then(async permissions => {
        console.log(permissions)
        if (!permissions.error) {

          const areasToEdit2 = { ...this.state.areasToEdit }

          if (permissions.areas) await Object.keys(areasToEdit2).forEach(key => {
            if (permissions.areas[key]) areasToEdit2[key] = permissions.areas[key]
          })

          this.setState({
            ...permissions,
            isLoading: false,
            areasToEdit: areasToEdit2
          })
        } else if (permissions.error.message.startsWith('NotFoundError')) {
          this.setState({
            isLoading: false,
          })
        }
      })
  } // componentDidMount

  handleSubmit = async (e) => {
    e.preventDefault()

    const { userId, companyId } = this.props
    const { canAccess, canAdmin, areasToEdit } = this.state
    const { token } = this.context
    const areas2 = {}

    await Object.keys(areasToEdit).forEach(key => {
      if (areasToEdit[key].canCreate || areasToEdit[key].canReview) {
        areas2[key] = areasToEdit[key]
      }
    })

    this.setState({ areas: areas2 })
    const { areas } = this.state

    this.setState({
      isLoading: true,
    })

    updateUserPermissions(token, userId, companyId, 'IPER', {
      canAccess, canAdmin, areas
    }).then(permissions => {
      this.setState({
        ...permissions,
        isLoading: false,
      })
    })
  } // handleSubmit

  handleAreasToEdit = (id, prop, val) => {
    const areasToEdit = Object.assign({}, this.state.areasToEdit);
    areasToEdit[id][prop] = val
    this.setState({areasToEdit});
  }

  renderPermissionsBoxes (val) {
    const { areasToEdit } = this.state
      return (
        <FormGroup check key={val._id}>
          <b>{val.name}</b>
          <br/>
          <Label check>
            <Input type="checkbox" checked={areasToEdit[val._id].canCreate}
                  onChange={() => this.handleAreasToEdit (val._id, 'canCreate', !areasToEdit[val._id].canCreate)}/> Puede crear
          </Label>
          <br/>
          <Label check>
            <Input type="checkbox" checked={areasToEdit[val._id].canReview}
                  onChange={() => this.handleAreasToEdit (val._id, 'canReview', !areasToEdit[val._id].canReview)}/> Puede revisar
          </Label>
          <br/>
          <br/>
        </FormGroup>
      )
  } // renderPermissionsBoxes

  render () {
    const { canAccess, canAdmin, isLoading, userAreas } = this.state

    if (isLoading) {
      return 'Cargando...'
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <ListGroup>
          <ListGroupItem>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={canAccess}
                       onChange={() => this.setState({ canAccess: !canAccess })}/>
                <b>Puede acceder</b> 
              </Label>
            </FormGroup>
          </ListGroupItem>

          <ListGroupItem>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={canAdmin}
                       onChange={() => this.setState({ canAdmin: !canAdmin })}/> Puede administrar
              </Label>
            </FormGroup>
          </ListGroupItem>
          <ListGroupItem>
            <FormGroup>
              <Label for="exampleSelect">Areas</Label>
            </FormGroup>
            { userAreas.map(val => this.renderPermissionsBoxes(val)) }
          </ListGroupItem>
        </ListGroup>
  
        <div className="mt-4">
          <Button type="submit">Guardar cambios</Button>
        </div>
      </form>
    )
  } // render
} // IPER
