import React, { Component } from 'react'
import './App.css'
import RootRouter from './routers/RootRouter'
import { BrowserRouter } from 'react-router-dom'
import { UserContext } from './contexts'
import { ToastContainer } from 'react-toastify'
import jwtDecode from 'jwt-decode'
import { addTokenToBlackList, verifyToken } from './requests/blackList'


class App extends Component {
  constructor (props) {
    super(props)

    const token = localStorage.getItem('token')

    let bundle = {}
    if (token) {
      bundle = jwtDecode(token)
    }

    this.state = {
      bundle,
      token,
      checkLogin: this.checkLogin,
      setToken: this.setToken,
      logout: this.logout,
    }

    this.checkLogin();
    setInterval(this.checkLogin, 600000);
  }

  checkLogin = () => {
    console.log('!')
    const { token } = this.state;
    token &&
    verifyToken(token)
      .then(res => {
        res && this.logout() });
  }

  setToken = (token) => {
    const bundle = jwtDecode(token)
    this.setState({
      bundle, token
    }, () => {
      localStorage.setItem('token', token)
    })
  }

  logout = () => {
    addTokenToBlackList(this.state.token)
      .then(res => {
        this.setState({
          bundle: {},
          token: undefined
        })
        localStorage.removeItem('token')
        window.location.href = "/"
      })
  }

  render () {
    return (
      <UserContext.Provider value={this.state}>
        <BrowserRouter>
          <RootRouter/>
        </BrowserRouter>

        <ToastContainer autoClose={3500}/>
      </UserContext.Provider>
    )
  }
}

export default App
