import { ENDPOINT_BLACK_LIST } from '../config'

export const addTokenToBlackList = (token) => {
  return fetch(`${ENDPOINT_BLACK_LIST}/addTokenToBlackList`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  }).then(res => res.json())
}

export const verifyToken = (token) => {
  return fetch(`${ENDPOINT_BLACK_LIST}/verifyToken`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }).then(res => res.json())
}
