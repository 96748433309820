import React from 'react'
import Box from '../components/Box/Box'
import MinimalLayout from '../components/Layout/MinimalLayout'
import * as Yup from 'yup'
import FormikInput from '../components/Form/FormikInput'
import { Button } from 'reactstrap'
import { Formik } from 'formik'
import BoxBody from '../components/Box/BoxBody'
import { ENDPOINT_USERS } from '../config'
import { UserContext } from '../contexts'
import { toast } from 'react-toastify'

export default class Login extends React.Component {
  static contextType = UserContext

  componentDidMount() {
    // gbv, nose si estara bien asi pero la idea
    // es que si esta logeado, no deba ingresar nuevamente
    const { bundle } = this.context
    if (bundle._id) {
      this.props.history.push(`/users/companies`)
    }
  }
 
  handleSubmit = (form, { setSubmitting }) => {
    fetch(`${ENDPOINT_USERS}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(form)
    })
      .then(res => res.json())
      .then(res => {
        setSubmitting(false)

        if (res.errors) {
          toast.error(res.message)
        } else {
          this.handleSuccess(res)
        }
      })
  }

  handleSuccess = (user) => {
    this.context.setToken(user.token)
    this.props.history.push(`/users/companies`)
  }

  render() {
    return (
      <MinimalLayout>
        <Box title="Iniciar Sesión" subtext="Debe iniciar sesión para gestionar usuarios">
          <BoxBody>
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={Yup.object().shape({
                rut: Yup.string().required('Required'),
                password: Yup.string().required('Required'),
              })}
            >
              {props =>
                <form onSubmit={props.handleSubmit}>
                  <FormikInput formikProps={props} label="RUT" name="rut"/>
                  <FormikInput formikProps={props} label="Contraseña" type="password" name="password"/>

                  <div className="text-center mt-5">
                    <Button type="submit" disabled={props.isSubmitting}>Continuar</Button>
                  </div>
                </form>
              }
            </Formik>
          </BoxBody>
        </Box>
      </MinimalLayout>
    )
  }
}
